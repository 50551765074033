import { AxiosResponse } from 'axios';
import { useCallback } from 'react';

import useAuthenticatedFetch from '../../../fetch/useAuthenticatedFetch';

export type StatusType = 'ACTIVE' | 'GRACE_PERIOD' | 'BLOCKED';

export type SubscriptionData = {
    displayPrice: string;
    endDate: string;
    nextBillingDate: string;
    status: StatusType;
    type: 'PRO' | 'BUSINESS' | 'GROWTH' | 'START';
};

export type PaywallType =
    | 'start'
    | 'growth'
    | 'pro'
    | 'business'
    | 'enterprise';

type PaywallPlan = {
    addOns: Record<'channels' | 'products' | 'gmv', { displayPrice: string }>;
    currentPlan?: boolean;
    displayPrice?: string;
    id: string;
};

export type Paywall = Record<PaywallType, PaywallPlan>;

const useSubscriptionApi = () => {
    const { apiGet, apiPost, apiPut } = useAuthenticatedFetch();
    const getSubscription = useCallback(
        (organizationUuid: string) =>
            apiGet<SubscriptionData>(
                `/private/organizations/${organizationUuid}/subscription`
            ).then((data) => {
                if (data.status === 204) {
                    return undefined;
                }

                return data.data;
            }),
        [apiGet]
    );
    const getPlans: (organizationId: string) => Promise<Paywall> = useCallback(
        (organizationId: string) =>
            apiGet<Paywall>(
                `/private/organizations/${organizationId}/plans`
            ).then((response) => response.data),
        [apiGet]
    );
    const subscribe: (
        organizationId: string,
        planId: string,
        callbackUrl: string
    ) => Promise<AxiosResponse> = useCallback(
        (organizationId: string, planId: string, callbackUrl: string) => {
            return apiPost(
                `/private/organizations/${organizationId}/subscription`,
                {
                    callbackUrl: callbackUrl,
                    planId: planId,
                }
            );
        },
        [apiPost]
    );
    const initPaymentHistorySession: (
        organizationId: string,
        returnUrl: string
    ) => Promise<AxiosResponse> = useCallback(
        (organizationId: string, returnUrl: string) => {
            return apiPost(
                `/private/organizations/${organizationId}/payment-history`,
                {
                    returnUrl,
                }
            );
        },
        [apiPost]
    );

    const updateSubscription: (
        organizationId: string,
        planId: string,
        callbackUrl: string
    ) => Promise<AxiosResponse> = useCallback(
        (organizationId: string, planId: string, callbackUrl: string) => {
            return apiPut(
                `/private/organizations/${organizationId}/subscription`,
                {
                    callbackUrl: callbackUrl,
                    planId: planId,
                }
            );
        },
        [apiPut]
    );

    return {
        getSubscription,
        getPlans,
        subscribe,
        initPaymentHistorySession,
        updateSubscription,
    };
};

export default useSubscriptionApi;
