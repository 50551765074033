import { Outlet } from 'react-router-dom-v5-compat';

import { useI18n } from '@mirakl/i18n';
import { useErrorHandler } from '@mirakl/roma';

import useLoginContext from '../config/login/LoginProvider';

const OperatorSupportOnlyRoute = () => {
    const { formatMessage } = useI18n();
    const handleError = useErrorHandler();
    const { miraklSupport } = useLoginContext();

    if (!miraklSupport.isOperatorSupportUser) {
        handleError(new Error(formatMessage({ id: 'error.403.title' })));
    }

    return <Outlet />;
};

export default OperatorSupportOnlyRoute;
