import React, { useEffect, useState } from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    Alert,
    Flex,
    NavigationButton,
    Panel,
    useErrorHandler,
    useNavigate,
} from '@mirakl/roma';

import useOrganizationContext from '../OrganizationContext';
import OrganizationRunPage from '../OrganizationRunPage';

import NoSubscription from './NoSubscription';
import SubscriptionDetailsSection from './SubscriptionDetailsSection';
import SubscriptionPriceSection from './SubscriptionPriceSection';
import useSubscriptionApi, { StatusType } from './useSubscriptionApi';

type SubscriptionInfos = {
    currentPlan: CurrentPlanDetail;
};

export type CurrentPlanDetail = {
    displayPrice: string;
    endDate: string;
    nextBillingDate: string;
    status: StatusType;
    type: 'PRO' | 'BUSINESS' | 'GROWTH' | 'START';
};

const StatusAlert = ({
    organizationId,
    status,
}: {
    organizationId: string;
    status: StatusType;
}) => {
    const { formatMessage } = useI18n();
    const { initPaymentHistorySession } = useSubscriptionApi();
    const navigate = useNavigate();

    switch (status) {
        case 'GRACE_PERIOD':
            return (
                <Alert
                    action={{
                        label: formatMessage({
                            id: 'organization.subscription.page.alert-panel.action.GRACE_PERIOD',
                        }),
                        onClick: () => {
                            initPaymentHistorySession(
                                organizationId,
                                `${window.location.origin}/organizations/${organizationId}/subscription`
                            ).then((response) => {
                                navigate({
                                    href: response.headers.location,
                                });
                            });
                        },
                    }}
                    status="warning"
                    title={formatMessage({
                        id: 'organization.subscription.page.alert-panel.title.GRACE_PERIOD',
                    })}
                >
                    {formatMessage({
                        id: 'organization.subscription.page.alert-panel.message.GRACE_PERIOD',
                    })}
                </Alert>
            );
        case 'BLOCKED':
            return (
                <Alert
                    status="error"
                    title={formatMessage({
                        id: 'organization.subscription.page.alert-panel.title.BLOCKED',
                    })}
                >
                    {formatMessage({
                        id: 'organization.subscription.page.alert-panel.message.BLOCKED',
                    })}
                </Alert>
            );
        default:
            return null;
    }
};

const SubscriptionPage = () => {
    const { formatMessage } = useI18n();
    const [isLoading, setIsLoading] = useState(true);
    const {
        isLegacySubscription: isLegacySubscription,
        isOrganizationAdmin,
        uuid: organizationUuid,
    } = useOrganizationContext();
    const { getSubscription, initPaymentHistorySession } = useSubscriptionApi();
    const [subscriptionInfos, setSubscriptionInfos] =
        useState<SubscriptionInfos>();
    const handleError = useErrorHandler();

    useEffect(() => {
        getSubscription(organizationUuid)
            .then((data) => {
                if (data !== undefined) {
                    setSubscriptionInfos({
                        currentPlan: {
                            status: data.status,
                            nextBillingDate: data.nextBillingDate,
                            displayPrice: data.displayPrice,
                            type: data.type,
                            endDate: data.endDate,
                        },
                    });
                }
            })
            .catch(handleError)
            .finally(() => setIsLoading(false));
    }, [organizationUuid, setIsLoading, getSubscription, handleError]);

    return (
        <OrganizationRunPage
            customActions={
                !isLegacySubscription && (
                    <NavigationButton
                        disabled={!isOrganizationAdmin}
                        label={formatMessage({
                            id: 'organization.subscription.page.manage-plan',
                        })}
                        to={`/organizations/${organizationUuid}/paywall?callback=${window.location.origin}/organizations/${organizationUuid}/subscription`}
                        tooltipText={
                            !isOrganizationAdmin &&
                            formatMessage({
                                id: 'organization.subscription.page.manage-plan.disabled.tooltip',
                            })
                        }
                    />
                )
            }
            pageTitle={formatMessage({
                id: 'organization.subscription.page.title',
            })}
            size="md"
        >
            {subscriptionInfos ? (
                <>
                    <StatusAlert
                        organizationId={organizationUuid}
                        status={subscriptionInfos.currentPlan.status}
                    />
                    <Flex allowFullScreen flexDirection="row">
                        <Flex width="66%">
                            <Panel loading={isLoading}>
                                <Panel.Header
                                    title={formatMessage({
                                        id: 'organization.subscription.price',
                                    })}
                                />
                                <Panel.Content>
                                    <SubscriptionPriceSection
                                        currentPlanDetail={
                                            subscriptionInfos.currentPlan
                                        }
                                        isOrganizationAdmin={
                                            isOrganizationAdmin
                                        }
                                    />
                                </Panel.Content>
                            </Panel>
                        </Flex>
                        <Flex marginStart={4} width="33%">
                            <Panel loading={isLoading}>
                                <Panel.Header
                                    title={formatMessage({
                                        id: 'organization.subscription.details',
                                    })}
                                />
                                <Panel.Content>
                                    <SubscriptionDetailsSection
                                        currentPlanDetail={
                                            subscriptionInfos.currentPlan
                                        }
                                        initPaymentHistorySession={
                                            initPaymentHistorySession
                                        }
                                        isOrganizationAdmin={
                                            isOrganizationAdmin
                                        }
                                        organizationId={organizationUuid}
                                    />
                                </Panel.Content>
                            </Panel>
                        </Flex>
                    </Flex>
                </>
            ) : (
                <Flex>
                    <NoSubscription
                        isLegacySubscription={isLegacySubscription}
                        isOrganizationAdmin={isOrganizationAdmin}
                        organizationUuid={organizationUuid}
                    />
                </Flex>
            )}
        </OrganizationRunPage>
    );
};

export default SubscriptionPage;
